.nav {
    height: 8rem;
    background-color: $color-bg-elem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    text-transform: uppercase;
    z-index: 100;
    box-shadow: 0 0 1rem 0.1rem hsla(0, 0%, 40%, 0.15);

    &__center {
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1000px;
        flex-wrap: wrap;

        @media (min-width: $dim-breakpoint-md) {
            flex-wrap: nowrap;
        }
    }

    &__links {
        display: none;
        font-size: 1.6rem;
        color: $color-primary-c;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        background-color: $color-bg-elem;
        flex: 1 0 100%;

        @media (min-width: $dim-breakpoint-md) {
            display: flex;
            flex-direction: row;
            flex: 1 0 65%;
            height: 8rem;
            align-items: center;
        }

        &-left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            @media (min-width: $dim-breakpoint-md) {
                flex-direction: row;
                overflow: hidden;
            }
        }
    }

    &__link {
        display: flex;
        text-align: center;
        padding: 1rem;
        margin: 0.5rem;
        color: $color-primary;
        text-decoration: none;
        overflow: hidden;

        font-size: 1.4rem;
        &.active {
            font-weight: bold;
        }

        @media (min-width: $dim-breakpoint-md) {
            align-items: center;
            &.active {
                font-weight: normal;
                border-bottom: 1px solid $color-primary;
            }
        }
    }

    &__logo {
        display: flex;
        justify-content: flex-start;
        height: 6rem;
        width: 20rem;
        padding: 0 0 0 2rem;

        &-image {
            width: 100%;
            height: 100%;
        }

        @media (min-width: $dim-breakpoint-md) {
            padding: 0;
        }
    }

    &__right {
        display: flex;
        justify-content: flex-end;
        height: 8rem;
        width: 8rem;

        &-button {
            @include ff-icon;
            padding: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Material Icons, sans-serif;
            font-size: 2.5rem;
            color: $color-primary;
        }

        @media (min-width: $dim-breakpoint-md) {
            display: none;
        }
    }

    &__logout {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (min-width: $dim-breakpoint-md) {
            flex-direction: row;
        }

        &-help {
            display: flex;
            color: $color-primary;

            &-button {
                // @include ff-icon;
                padding: 1.35rem 1rem;
                margin: 0 1rem;
                border-radius: 0.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.2rem;
                font-weight: bold;
                background-color: $color-primary;
                color: $color-primary-c;
                text-decoration: none;
                border: 1px solid $color-primary;
            }
        }
    }

    .border-top {
        border-top: 1px solid $color-primary;
    }

    .font-size-14 {
        font-size: 1.2rem;
    }

    &.is-open {
        .nav {
            &__links {
                display: flex;
                border-bottom: 1px solid $color-grey-1;
                box-shadow: 0 0 0.1rem 0.1rem hsla(0, 0%, 40%, 0.15);
            }
        }
    }
}

.router-home {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-top: 10rem;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;
    }
}
