.page {
    max-width: $dim-page-max-width;
    width: 100%;
    background-color: $color-bg-elem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 1rem 0;
    box-shadow: 0 0 1rem 0.1rem hsla(0,0%,40%,.15);


    &__header {
        width: 100%;
        padding: 1rem 1rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: $color-primary;
        background-color: $color-bg-elem;
        text-transform: uppercase;
        text-align: left;

        &.with-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &.is-warning {
            color: $color-red;
        }
    }

    &-buttons {
        width: 100%;
        padding: 1rem;
        color: $color-primary;
        background-color: $color-bg-elem;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        flex-direction: column;

        .button {
            margin-right: 0;
            margin-bottom: 1rem;
        }

        @media (min-width: $dim-breakpoint-sm) {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .button {
                margin-right: 1rem;
            }

        }
    }

    &-table {
        width: 100%;
        max-width: 100%;
        padding: 1rem 1rem;
        overflow-x: auto;
    }

    &-banner {
        width: 100%;

        &__img {
            width: 100%;
            border-bottom: 1px solid $color-grey-5;
        }
    }

    &.margin-bottom-13 {
        margin-bottom: 13rem;
        
        @media(min-width: $dim-breakpoint-sm) {
            margin-bottom: 0;
        }
    }

    &.no-padding-top {
        padding-top: 0;
    }
}
